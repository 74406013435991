<template>
  <section class="official--partner-header" v-if="areaDetail">
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="section--gallery">
          <client-only>
            <vue-gallery-slideshow
              :images="galleryImages"
              :index="imageIndex"
              @close="imageIndex = null"
            ></vue-gallery-slideshow>
          </client-only>
          <v-tabs>
            <v-tab :href="`#tab-1`" v-if="areaDetail.photos && areaDetail.photos.length > 0">{{
              $t(`general.gallery`)
            }}</v-tab>
            <v-tab :href="`#tab-2`" v-if="areaDetail.description">{{
              $t(`officialPartner.partnerDesc`)
            }}</v-tab>
            <v-tab :href="`#tab-3`" v-if="areaDetail.pano_embed_url">{{
              $t(`general.panorama`)
            }}</v-tab>
            <v-tab :href="`#tab-4`" v-if="areaDetail.amenities && areaDetail.amenities.length > 0">
              {{ $t(`general.amenity`) }}</v-tab
            >
            <v-tab :href="`#tab-5`" v-if="areaDetail.lat && areaDetail.lng">{{
              $t(`officialPartner.map`)
            }}</v-tab>

            <v-tab-item :value="'tab-1'" v-if="areaDetail.photos && areaDetail.photos.length > 0">
              <div @click="showImage">
                <!--                <img class="display&#45;&#45;photos" :src="areaDetail.photos[0]" :alt="areaDetail.name" />-->
                <picture>
                  <source :srcset="areaDetail.photos[0]" type="image/webp" />
                  <source :srcset="getFallbackImage(areaDetail.photos[0])" type="image/jpeg" />
                  <img
                    :src="getFallbackImage(areaDetail.photos[0])"
                    class="display--photos"
                    :alt="areaDetail.name"
                  />
                </picture>
                <button class="btn btn-primary--outline open--gallery" @click="showImage">
                  <i class="ion-images"></i>
                  <span v-if="areaDetail.photos !== null"
                    >{{ $t('general.view1') }} {{ areaDetail.photos.length }}
                    {{ $t('general.view2') }}</span
                  >
                </button>
              </div>
            </v-tab-item>
            <v-tab-item class="partner--description" :value="'tab-2'" v-if="areaDetail.description">
              <div>
                {{ areaDetail.description }}
              </div>
            </v-tab-item>
            <v-tab-item :value="'tab-3'" v-if="areaDetail.pano_embed_url">
              <iframe
                :src="areaDetail.pano_embed_url"
                width="100%"
                height="360"
                frameborder="0"
                style="border: 0"
                allowfullscreen
                loading="lazy"
              ></iframe>
            </v-tab-item>
            <v-tab-item
              class="partner--amenity"
              :value="'tab-4'"
              v-if="areaDetail.amenities && areaDetail.amenities.length > 0"
            >
              <Amenity :amenities="areaDetail.amenities"></Amenity>
            </v-tab-item>
            <v-tab-item :value="'tab-5'" v-if="areaDetail.lat && areaDetail.lng">
              <Map :lat="areaDetail.lat" :lng="areaDetail.lng"></Map>
            </v-tab-item>
          </v-tabs>
        </div>
        <Overview
          :name="areaDetail.name"
          :address="addressLabel"
          :type="areaDetail.property_type"
          :listingType="areaDetail.listing_type"
        />
        <Detail
          :doneYearEst="areaDetail.done_year_est ? areaDetail.done_year_est.toString() : null"
          :minPrice="areaDetail.min_price"
          :maxPrice="areaDetail.max_price"
          :rentPeriod="areaDetail.price_range_rent_period"
          :type="areaDetail.property_type"
          :listingType="areaDetail.listing_type"
        />
      </div>
      <div class="col-12 col-lg-4">
        <ActionSection
          class="mb-4"
          :partner_image="areaDetail.partner_logo_photo"
          :partner_web="null"
          :name="areaDetail.partner_name"
          :description="null"
          v-if="areaDetail.partner_name"
        />
        <DeveloperSection
          :developer_image="areaDetail.developer_photo"
          :developer_web="null"
          :name="areaDetail.developer_name"
          v-if="areaDetail.developer_name"
        />
      </div>
    </div>
  </section>
</template>

<script>
const VueGallerySlideshow = () => import('vue-gallery-slideshow');
const Overview = () => import('@/components/official-partner/overview');
const Detail = () => import('@/components/official-partner/detail');
const ActionSection = () => import('@/components/official-partner/action');
const DeveloperSection = () => import('@/components/official-partner/developer');
const Amenity = () => import('@/components/official-partner/amenity');
const Map = () => import('@/components/official-partner/maps');
import HelperMixin from '@/mixins/helpers';

export default {
  props: ['areaDetail'],
  mixins: [HelperMixin],
  components: {
    VueGallerySlideshow,
    Overview,
    Detail,
    ActionSection,
    DeveloperSection,
    Amenity,
    Map,
  },
  data: () => ({
    imageIndex: null,
  }),
  computed: {
    galleryImages() {
      let images = [];
      for (let photo of this.areaDetail.photos) {
        images.push({
          url: photo,
          alt: this.areaDetail.name + ' - Rentfix',
        });
      }
      return images;
    },
    addressLabel() {
      let address = this.areaDetail.address;
      if (this.areaDetail.region) {
        address += ', ' + this.areaDetail.region;
      }
      return address;
    },
  },
  methods: {
    showImage() {
      this.imageIndex = 0;
    },
  },
};
</script>
